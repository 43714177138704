import { useEffect } from 'react';

const Hallescherfc = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 559);
    localStorage.setItem('club_name', 'Hallescher FC');

    window.location.assign('/');
  });

  return null;
};
export default Hallescherfc;
